import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import React, { useEffect, useRef, useState } from 'react';
import { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';
import classNames from 'classnames';
import moment, { Moment } from 'moment';

import { IBlockDaysConfig } from 'components/Popups/DatePickerPopup/DatePickerPopup';
import { DoubleBtnGroup } from 'components/Reusable';

import { useWindowSize } from 'hooks';
import { dateFormat } from 'utils';

import styles from './DateRangePicker.module.scss';

import ActionButton from './ActionButton';
import PrimaryButton from './PrimaryButton';

export interface DateRangePickerStrings {
  from?: string;
  last2Weeks?: string;
  last3Months?: string;
  last7Days?: string;
  lastMonth?: string;

  // Single Date actions
  in3Months?: string;
  in6Months?: string;
  in1Year?: string;
  in2Year?: string;

  submit?: string;
  to?: string;
}

export enum Filter {
  LastWeek,
  Last2Weeks,
  LastMonth,
  Last3Months,
}

export enum SingleDateFilter {
  In3Months,
  In6Months,
  In1Year,
  In2Year,
}

export interface DateRangePickerProps {
  isOpenSidebar?: boolean;
  hideFilters?: boolean;
  hideSubmitButton?: boolean;
  windowWidth?: number;
  onCancel?: () => void;
  smallSize?: boolean;

  // Date Range Picker Props
  defaultEndDate?: Date;
  defaultStartDate?: Date;
  endDate?: Date;
  numberOfMonths?: number;
  onChange?: (startDate: Date | undefined, endDate: Date | undefined, filter?: Filter) => void;
  onSubmit?: (startDate: Date | undefined, endDate: Date | undefined) => void;
  startDate?: Date;
  strings?: DateRangePickerStrings;

  // Single Day Picker Props
  blockPastDates?: boolean;
  blockDaysConfig?: IBlockDaysConfig;
  isSingleDayPicker?: boolean;
  showSingleDateActionButtons?: boolean;
  date?: string;
  onChangeSingleDate?: (date: Date | undefined, filter?: SingleDateFilter) => void;
  onSubmitSingleDate?: (date: Date | undefined) => void;
}

interface DateRangeState {
  endDate: Moment | null | undefined;
  endDateText: string;
  focusedInput: 'endDate' | 'startDate' | null;
  startDate: Moment | null | undefined;
  startDateText: string;
}

type SingleDateState = Moment | null | undefined;

interface DateRangePickerCtx {
  props: DateRangePickerProps;
  setState: React.Dispatch<React.SetStateAction<DateRangeState>>;
  state: DateRangeState;
  key: number;
  setKey: React.Dispatch<React.SetStateAction<number>>;
  isSmallSize: boolean;

  // Single Day Picker Props
  stateSingleDate: SingleDateState;
  setStateSingleDate: React.Dispatch<React.SetStateAction<SingleDateState>>;
}

const navNextButtonRender = (props: {
  ariaLabel: string; // Incorrect OOTB attribute for button
  disabled: boolean;
  onClick?: React.MouseEventHandler<Element>;
  onKeyUp?: React.KeyboardEventHandler<Element>;
  onMouseUp?: React.MouseEventHandler<Element>;
}): JSX.Element => (
  <button
    className={`nav-button nav-next-button ${styles.navButton} ${styles.navNextButton}`}
    disabled={props.disabled}
    onClick={props.onClick}
    onKeyUp={props.onKeyUp}
    onMouseUp={props.onMouseUp}
  >
    <svg
      className={styles.navButtonIcon}
      width="24"
      height="24"
      viewBox="0 0 10 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.703127 0.906249L0.429689 1.17969C0.234377 1.375 0.234377 1.64844 0.429689 1.84375L8.04688 9.5L0.429688 17.1172C0.234375 17.3125 0.234375 17.5859 0.429688 17.7812L0.703125 18.0547C0.898438 18.25 1.17188 18.25 1.36719 18.0547L9.60938 9.8125C9.80469 9.61719 9.80469 9.34375 9.60938 9.14844L1.36719 0.906249C1.17188 0.710937 0.898439 0.710937 0.703127 0.906249Z" />
    </svg>
  </button>
);

const navPrevButtonRender = (props: {
  ariaLabel: string; // Incorrect OOTB attribute for button
  disabled: boolean;
  onClick?: React.MouseEventHandler<Element>;
  onKeyUp?: React.KeyboardEventHandler<Element>;
  onMouseUp?: React.MouseEventHandler<Element>;
}): JSX.Element => (
  <button
    className={`nav-button nav-prev-button ${styles.navButton} ${styles.navPrevButton}`}
    disabled={props.disabled}
    onClick={props.onClick}
    onKeyUp={props.onKeyUp}
    onMouseUp={props.onMouseUp}
  >
    <svg
      className={styles.navButtonIcon}
      width="24"
      height="24"
      viewBox="0 0 10 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.29688 18.0938L9.57031 17.8203C9.76562 17.625 9.76562 17.3516 9.57031 17.1562L1.95312 9.5L9.57031 1.88281C9.76562 1.6875 9.76562 1.41406 9.57031 1.21875L9.29688 0.945312C9.10156 0.75 8.82812 0.75 8.63281 0.945312L0.390625 9.1875C0.195312 9.38281 0.195312 9.65625 0.390625 9.85156L8.63281 18.0938C8.82812 18.2891 9.10156 18.2891 9.29688 18.0938Z" />
    </svg>
  </button>
);

const onFilterClick = (filter: Filter, ctx: DateRangePickerCtx): void => {
  let dates: { endDate: Date; startDate: Date } = { endDate: new Date(), startDate: new Date() };
  switch (filter) {
    case Filter.LastWeek:
      dates = { endDate: new Date(), startDate: moment().add(-1, 'week').toDate() };
      break;
    case Filter.Last2Weeks:
      dates = { endDate: new Date(), startDate: moment().add(-2, 'weeks').toDate() };
      break;
    case Filter.LastMonth:
      dates = { endDate: new Date(), startDate: moment().add(-1, 'month').toDate() };
      break;
    case Filter.Last3Months:
      dates = { endDate: new Date(), startDate: moment().add(-3, 'months').toDate() };
      break;
  }
  const endDate = moment(dates.endDate);
  const endDateText = endDate.format(dateFormat);
  const startDate = moment(dates.startDate);
  const startDateText = startDate.format(dateFormat);

  ctx.setKey((prevState) => ++prevState);

  ctx.props.onChange
    ? ctx.props.onChange(dates.startDate, dates.endDate, filter)
    : ctx.setState((ps) => ({ ...ps, endDate, endDateText, startDate, startDateText }));
};

const onSingleDateFilterClick = (filter: SingleDateFilter, ctx: DateRangePickerCtx): void => {
  let date = new Date();

  switch (filter) {
    case SingleDateFilter.In3Months:
      date = moment().add(3, 'months').toDate();
      break;
    case SingleDateFilter.In6Months:
      date = moment().add(6, 'months').toDate();
      break;
    case SingleDateFilter.In1Year:
      date = moment().add(1, 'year').toDate();
      break;
    case SingleDateFilter.In2Year:
      date = moment().add(2, 'years').toDate();
      break;
  }
  ctx.setKey((prevState) => ++prevState);

  ctx.props.onChangeSingleDate && ctx.props.onChangeSingleDate(date, filter);
  ctx.setStateSingleDate(moment(date));
};

const toolbarRender = (ctx: DateRangePickerCtx): JSX.Element => {
  const submitText = ctx.props.strings?.submit || 'Apply';
  const lastWeekText = ctx.props.strings?.last7Days || 'Last 7 days';
  const last2WeeksText = ctx.props.strings?.last2Weeks || 'Last 2 weeks';
  const lastMonthText = ctx.props.strings?.lastMonth || 'Last month';
  const last3MonthsText = ctx.props.strings?.last3Months || 'Last 3 months';
  const onLastWeekClick = () => onFilterClick(Filter.LastWeek, ctx);
  const onLast2WeeksClick = () => onFilterClick(Filter.Last2Weeks, ctx);
  const onLastMonthClick = () => onFilterClick(Filter.LastMonth, ctx);
  const onLast3MonthsClick = () => onFilterClick(Filter.Last3Months, ctx);

  // Single Date Actions
  const in3MonthsText = ctx.props.strings?.in3Months || 'In 3 Months';
  const in6MonthsText = ctx.props.strings?.in6Months || 'In 6 Months';
  const in1YearText = ctx.props.strings?.in1Year || 'In 1 Year';
  const in2YearText = ctx.props.strings?.in2Year || 'In 2 Years';
  const on3MonthsClick = () => onSingleDateFilterClick(SingleDateFilter.In3Months, ctx);
  const on6MonthsClick = () => onSingleDateFilterClick(SingleDateFilter.In6Months, ctx);
  const on1YearClick = () => onSingleDateFilterClick(SingleDateFilter.In1Year, ctx);
  const on2YearClick = () => onSingleDateFilterClick(SingleDateFilter.In2Year, ctx);

  const onSubmit = () => {
    ctx.props.onSubmit && ctx.props.onSubmit(ctx.state.startDate?.toDate(), ctx.state.endDate?.toDate());
  };

  const onSubmitSingleDate = () => {
    ctx.props.onSubmitSingleDate && ctx.props.onSubmitSingleDate(ctx.stateSingleDate?.toDate());
  };

  const onCancel = () => {
    ctx.props.onCancel && ctx.props.onCancel();
  };

  return (
    <div
      className={classNames('toolbar', styles.toolbar, {
        [styles.singleDateToolbar]: ctx.props.showSingleDateActionButtons,
        [styles.toolbarIsOpenSidebar]: ctx.isSmallSize && !ctx.props.isSingleDayPicker,
      })}
    >
      {!(ctx.props.hideFilters || ctx.props.isSingleDayPicker) && (
        <div className="filters">
          <ActionButton className={styles.filterButton} onClick={onLastWeekClick} text={lastWeekText} />
          <ActionButton className={styles.filterButton} onClick={onLast2WeeksClick} text={last2WeeksText} />
          <ActionButton className={styles.filterButton} onClick={onLastMonthClick} text={lastMonthText} />
          <ActionButton className={styles.filterButton} onClick={onLast3MonthsClick} text={last3MonthsText} />
        </div>
      )}
      {!ctx.props.hideFilters && ctx.props.isSingleDayPicker && ctx.props.showSingleDateActionButtons && (
        <div className="filters">
          <ActionButton className={styles.filterButton} onClick={on3MonthsClick} text={in3MonthsText} />
          <ActionButton className={styles.filterButton} onClick={on6MonthsClick} text={in6MonthsText} />
          <ActionButton className={styles.filterButton} onClick={on1YearClick} text={in1YearText} />
          <ActionButton className={styles.filterButton} onClick={on2YearClick} text={in2YearText} />
        </div>
      )}
      {!ctx.props.hideSubmitButton && (
        <DoubleBtnGroup
          className={styles.doubleBGroup}
          name1="Cancel"
          name2={submitText}
          onClick1={onCancel}
          onClick2={ctx.props.isSingleDayPicker ? onSubmitSingleDate : onSubmit}
          disabled={
            ctx.props.isSingleDayPicker
              ? !ctx.stateSingleDate?.isValid()
              : !ctx.state.startDate?.isValid() || !ctx.state.endDate?.isValid()
          }
          hideButton1={ctx.props.isSingleDayPicker && ctx.props.showSingleDateActionButtons}
          type1="button"
          type2="button"
        />
      )}
    </div>
  );
};

const inputsRender = ({ props, setState, state }: DateRangePickerCtx): JSX.Element => {
  const endDateOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const endDate = e.target.value ? moment(e.target.value, dateFormat, true) : undefined;
    const startDateZeros = state.startDate?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDateZeros = endDate?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const isValid = endDate ? endDate.isValid() && endDateZeros?.isAfter(startDateZeros) : true;

    setState((ps) => ({
      ...ps,
      endDate: isValid ? endDate : null,
      endDateText: e.type === 'blur' && !isValid ? '' : e.target.value,
    }));

    props.onChange && isValid && props.onChange(state.startDate?.toDate(), endDate?.toDate());
  };
  const startDateOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const startDate = e.target.value ? moment(e.target.value, dateFormat, true) : undefined;
    const startDateZeros = startDate?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDateZeros = state.endDate?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const isValidPastDate = props.blockPastDates
      ? startDateZeros?.isSameOrAfter(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
      : true;

    const isValid = startDate
      ? startDate.isValid() && isValidPastDate && (!state.endDate || endDateZeros?.isAfter(startDateZeros))
      : true;

    setState((ps) => ({
      ...ps,
      startDate: isValid ? startDate : null,
      startDateText: e.type === 'blur' && !isValid ? '' : e.target.value,
    }));

    props.onChange && isValid && props.onChange(startDate?.toDate(), state.endDate?.toDate());
  };
  return (
    <div className={`${styles.inputPanel} input-panel`}>
      <div className={`${styles.inputContainer} from-input`}>
        <label className={styles.label} htmlFor="date-range-picker-from">
          {props.strings?.from || 'From'}
        </label>
        <input
          className={styles.input}
          id="date-range-picker-from"
          onChange={startDateOnChange}
          onBlur={startDateOnChange}
          type="text"
          value={state.startDateText}
        />
      </div>
      <div className={`${styles.inputContainer} to-input`}>
        <label className={styles.label} htmlFor="date-range-picker-to">
          {props.strings?.from || 'To'}
        </label>
        <input
          className={styles.input}
          id="date-range-picker-to"
          onChange={endDateOnChange}
          onBlur={endDateOnChange}
          type="text"
          value={state.endDateText}
        />
      </div>
    </div>
  );
};

const controllerRender = ({
  props,
  setState,
  state,
  key,
  setStateSingleDate,
  stateSingleDate,
  isSmallSize,
}: DateRangePickerCtx): JSX.Element => {
  const initialVisibleMonth = (): Moment =>
    props.numberOfMonths === 1 || isSmallSize
      ? state.endDate || moment()
      : (state.endDate && moment(state.endDate.valueOf()).add(-1, 'month')) || moment().add(-1, 'month');

  const initialVisibleMonthSingleDate = (): Moment =>
    !Number.isNaN(stateSingleDate?.month()) && stateSingleDate?.month() !== undefined ? stateSingleDate : moment();

  const onSingleDateChange = (date: Moment | null) => {
    props.onChangeSingleDate ? props.onChangeSingleDate(date?.toDate()) : setStateSingleDate(date);
  };

  const onDatesChange = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) => {
    const endDateText = endDate ? endDate.format(dateFormat) : '';
    const startDateText = startDate ? startDate.format(dateFormat) : '';
    props.onChange
      ? props.onChange(startDate?.toDate(), endDate?.toDate())
      : setState((ps) => ({ ...ps, endDate, endDateText, startDate, startDateText }));
  };

  const getIsDayBlocked = (day: Moment) => {
    const dayStart = moment(day).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const blockedDayStart = props.blockDaysConfig
      ? moment(props.blockDaysConfig?.day).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      : null;
    const todayStart = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const isPastDatesBlocked = props.blockPastDates ? todayStart.isAfter(dayStart) : false;
    let isBlockedPeriod = false;

    if (props.blockDaysConfig) {
      if (props.blockDaysConfig.period === 'before') {
        isBlockedPeriod = props.blockDaysConfig.isDayIncluded
          ? dayStart.isSameOrBefore(blockedDayStart)
          : dayStart.isBefore(blockedDayStart);
      } else {
        isBlockedPeriod = props.blockDaysConfig.isDayIncluded
          ? dayStart.isSameOrAfter(blockedDayStart)
          : dayStart.isAfter(blockedDayStart);
      }
    }

    return isBlockedPeriod || isPastDatesBlocked;
  };

  return props?.isSingleDayPicker ? (
    <DayPickerSingleDateController
      key={key}
      date={stateSingleDate || null}
      onDateChange={onSingleDateChange}
      focused={true}
      onFocusChange={({ focused }) => focused}
      initialVisibleMonth={initialVisibleMonthSingleDate}
      daySize={33}
      weekDayFormat={'dd'}
      hideKeyboardShortcutsPanel={true}
      noBorder={true}
      renderNavNextButton={navNextButtonRender}
      renderNavPrevButton={navPrevButtonRender}
      isDayBlocked={getIsDayBlocked}
    />
  ) : (
    <DayPickerRangeController
      key={key}
      endDate={state.endDate || null}
      focusedInput={state.focusedInput}
      hideKeyboardShortcutsPanel={true}
      horizontalMonthPadding={10}
      initialVisibleMonth={initialVisibleMonth}
      minimumNights={0} // Allow to select the same end date as start date
      noBorder={true}
      numberOfMonths={isSmallSize ? 1 : props.numberOfMonths || 2}
      onDatesChange={onDatesChange}
      onFocusChange={(focusedInput) => setState((ps) => ({ ...ps, focusedInput: focusedInput || 'startDate' }))}
      renderNavNextButton={navNextButtonRender}
      renderNavPrevButton={navPrevButtonRender}
      startDate={state.startDate || null}
      daySize={33}
      weekDayFormat={'dd'}
      isDayBlocked={getIsDayBlocked}
    />
  );
};

const getInitialState = (props: DateRangePickerProps): DateRangeState => {
  const endDate =
    (props.endDate && moment(props.endDate)) || (props.defaultEndDate && moment(props.defaultEndDate)) || null;
  const startDate =
    (props.startDate && moment(props.startDate)) || (props.defaultStartDate && moment(props.defaultStartDate)) || null;

  return {
    endDate,
    endDateText: endDate ? endDate.format(dateFormat) : '',
    focusedInput: 'startDate',
    startDate,
    startDateText: startDate ? startDate.format(dateFormat) : '',
  };
};

const useUpdatedDates = ({ props, setState, setStateSingleDate }: DateRangePickerCtx): void => {
  // Do not update on did mount
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      const date = props.date ? moment(props.date) : undefined;
      const endDate = props.endDate ? moment(props.endDate) : undefined;
      const endDateText = endDate ? endDate.format(dateFormat) : '';
      const startDate = props.startDate ? moment(props.startDate) : undefined;
      const startDateText = startDate ? startDate.format(dateFormat) : '';
      setState((ps) => ({ ...ps, endDate, endDateText, startDate, startDateText }));
      setStateSingleDate(date);
    } else {
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startDate, props.endDate, props.date]);
};

const DateRangePicker = (props: DateRangePickerProps): JSX.Element => {
  const { width: windowWidth } = useWindowSize();

  const [state, setState] = useState<DateRangeState>(() => getInitialState(props));
  const [stateSingleDate, setStateSingleDate] = useState<SingleDateState>((props.date && moment(props.date)) || null);
  const [key, setKey] = useState<number>(0);

  const isSmallSize = !!(
    props.smallSize ||
    (windowWidth || 0) < 1024 ||
    ((windowWidth || 0) < 1380 && props.isOpenSidebar)
  );

  const ctx: DateRangePickerCtx = {
    props: { ...props, windowWidth },
    setState,
    state,
    setStateSingleDate,
    stateSingleDate,
    key,
    setKey,
    isSmallSize,
  };
  const submitText = ctx.props.strings?.submit || 'Apply';

  const onSubmit = () => {
    ctx.props.onSubmit && ctx.props.onSubmit(ctx.state.startDate?.toDate(), ctx.state.endDate?.toDate());
  };

  const onSubmitSingle = () => {
    ctx.props.onSubmitSingleDate &&
      ctx.props.onSubmitSingleDate(
        ctx.stateSingleDate && ctx.stateSingleDate?.isValid() ? ctx.stateSingleDate?.toDate() : undefined
      );
  };

  const onCancel = () => {
    ctx.props.onCancel && ctx.props.onCancel();
  };

  useUpdatedDates(ctx);

  return (
    <div className={`wcm-date-range-picker ${styles.container}`}>
      <div className={`controller ${styles.controller} ${props.isSingleDayPicker ? styles.singleDateController : ''}`}>
        {!props.isSingleDayPicker && inputsRender(ctx)}
        {controllerRender(ctx)}
        {!ctx.props.hideSubmitButton && !ctx.props.isSingleDayPicker && (
          <PrimaryButton
            className={classNames(styles.toolbarButton, {
              [styles.singleDateToolbarButton]: props.isSingleDayPicker,
              [styles.singleDateToolbarButtonWithActions]: isSmallSize && !props.isSingleDayPicker,
            })}
            onClick={onSubmit}
            text={submitText}
          />
        )}
        {!ctx.props.hideSubmitButton && ctx.props.isSingleDayPicker && !ctx.props.showSingleDateActionButtons && (
          <DoubleBtnGroup
            className={styles.doubleBGroup}
            name1="Cancel"
            name2={submitText}
            onClick1={onCancel}
            onClick2={onSubmitSingle}
            type1="button"
            type2="button"
            buttonSize="xl"
          />
        )}
      </div>
      {!(!props.showSingleDateActionButtons && props.isSingleDayPicker) && toolbarRender(ctx)}
    </div>
  );
};

export default DateRangePicker;
